// TODO: add pluralized?
// The `vacancy` argument is added for compatibility with the original useVacancyTypeName, but we're not using it on Stagedoos (yet, at least)
export default function useVacancyTypeName(vacancy?: Vacancy) {
  const { t: $t } = useI18n({ useScope: 'global' })

  const isMas = useIsMas()
  const vacancyType = isMas ? 'mas' : 'traineeship'

  const vacancyTypeName = computed(() =>
    $t(
      `vacancy.attributes.vacancyTypeName.${vacancyType}.singular`,
    ),
  )

  const vacancyTypeNamePlural = computed(() =>
    $t(
      `vacancy.attributes.vacancyTypeName.${vacancyType}.plural`,
    ),
  )

  const vacancyTypeNameCapitalized = computed(() =>
    capitalize(vacancyTypeName.value),
  )

  return {
    vacancyTypeName: vacancyTypeName.value,
    vacancyTypeNameCapitalized: vacancyTypeNameCapitalized.value,
    vacancyTypeNamePlural: vacancyTypeNamePlural.value,
  }
}
